import { ApplicationRef, NgModuleRef } from '@angular/core';
import { Environment } from './model';

Error.stackTraceLimit = Infinity;

export const environment: Environment = {
  production: true,
  cielo3DsProduction: true,
  showDevModule: false,
  logRocket: false,
  apiConfig: 'https://services.vipcommerce.com.br/api-externa',
  wsUrl: 'https://services.vipcommerce.com.br/ws/loja',
  apiUrl: 'https://services.vipcommerce.com.br/api-admin/v1',  
  showCaptcha: false,
  apiIntegracaoUrl: 'https://services.vipcommerce.com.br/api-externa',
  apiOrganizacaoUrl: 'https://services.vipcommerce.com.br/organizacoes',
  horizontalizado: true,

  /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
  decorateModuleRef(modRef: NgModuleRef<any>) {
    const appRef = modRef.injector.get(ApplicationRef);
    const cmpRef = appRef.components[0];

    let _ng = (window as any).ng;
    (window as any).ng.probe = _ng.probe;
    (window as any).ng.coreTokens = _ng.coreTokens;
    return modRef;
  },
  ENV_PROVIDERS: [],
};
